
import { Options, Vue } from "vue-class-component";
import News from "@/components/News.vue";

@Options({
	components: {
		News,
	},
})
export default class NewsV extends Vue {}
